.feature-table {
  display: block;
  overflow-x: auto;

  .lp3-btn-green, .lp3-btn-orange, .lp3-btn-grey, .lp3-btn-blue, .lp3-btn-white {
    padding: 0.35em 2em;
  }

  th {
    h3 {
      margin: 0.5em;
    }
    //background: #F0F9FF;
    border: 1px solid #f0f0f0;
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
    padding: 0.5em 1em;
    color: $blue;
    cursor: default;
    &:first-child {
      text-transform: none;
      min-width: 200px;
      text-align: left;
      color: $txt-grey;
      background-color: $almostwhite;
      &:hover {
        background: $almostwhite;
      }
    }

    &.free {
      color: $green;
      background: white;
    }

    &.basic {
      color: white;
      background: $green;
    }

    &.gold {
      color: white;
      background: $orange;
    }

    &.platin {
      color: white;
      background: $grey;

    }
    &.enterprise {
      color: white;
      background: $blue;

    }
  }

  td {
    font-size: 0.85em;
    border-bottom: 1px dotted $blue;
    padding: 0.5em 1em;
    text-align: center;
    cursor: default;

    &:first-child {
      text-align: left;
    }
    i {
      color: $green;
      &.partly {
        color: #c0c0c0;
      }
    }

  }

  tr {
    &:hover, &:hover td {
      background: white;
    }
    &:last-child {
      td {
        border-bottom: 25px solid transparent;
      }
      &:hover, &:hover td {
        background: transparent;
      }
    }
    &.category {
      &, td {
        cursor: pointer;
      }
      &:hover {
        background: white;
        td {
          background: white;
          &:first-child span {
            text-decoration: underline;
          }
        }
      }

      td {
        &:first-child {
          color: $blue;
          &:before {
            font-family: fontello;
            content: '\e82f';
            color: $blue;
            padding-right: 10px;
          }
        }

      }

      &.open td:first-child:before {
        content: '\e830';
      }
    }

    &.feature {
      td {
        &:first-child {
          padding-left: 2em;
        }
      }

    }
  }
}