@charset "UTF-8";

@import "_colors";
@import "_mixins";
@import "_inputs";
@import "_select";
@import "_checkbox";
@import "_radio";
@import "_overlay";
@import "_tooltip";
@import "_supportform";
@import "_table";
@import "../../lp3-cd/scss/lp3-cd-frame.scss";

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

#lamacms-body {
  overflow-x: hidden;
}

/* allgemeines */
p {
  margin: 0;
  padding: 0;
  zoom: 1;
  margin-bottom: 0.25em;
}

table {
  td {
    vertical-align: top;
  }
}

.noCookies-banner {
  bottom: -230px;
  left: 0;
  right: 0;
  height: 120px;
  background-color: rgba($almostblack, 0.9);
  box-shadow: 0px 0px 10px rgba($grey, .25);
  z-index: 2;
  padding: 1em 1.2em;
  @media (max-width: 1199px) {
    height: 160px;
  }
  @media (max-width: 991px) {
    text-align: center;
  }
  @media (max-width: 767px) {
    height: 230px;
    text-align: center;
  }

  .noCookies-container {
    display: flex;
    height: 100%;
    justify-content: space-around;
    @media (max-width: 991px) {
      flex-direction: column;
    }

    > .column {
      min-width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text-info {
      font-size: 1.2em;
      font-weight: normal;
      font-family: Ubuntu, 'Open Sans', Arial, sans-serif;

      .header {
        color: white;
        font-size: 1.2rem;
        line-height: 1.8em;
      }

      .small-text {
        font-size: 0.9em;
        color: $lightgrey;
        @media (max-width: 767px) {
          p {
            display: none
          }
        }
      }
    }

    .action-buttons {
      > a {
        width: 15em;
        line-height: 1.8;
        text-align: center;
        margin: .2em 2em;
        padding: 0.35em 2em;
        display: inline-block;
        border-radius: 2em;
        cursor: pointer;
        @media (max-width: 1199px) {
          margin: 1em .6em;
        }
        @media (max-width: 767px) {
          margin: .5em .2em;
          width: 11em;
        }
      }

      .lp3-btn-white-basic {
        color: white;
        border: 1px solid white;

        &:hover {
          color: $lightgrey;
          border: 1px solid $lightgrey;
        }
      }

      .lp3-btn-white {
        background: $almostwhite;

        &:hover {
          background: darken($almostwhite, 5%);
        }
      }
    }
  }
}

img.lp3-textimg {
  border: 1px solid $blue;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(0, 0, 0, .5);
}

img.responsive-img {
  width: 100% !important;
  height: auto !important;
  max-width: 800px !important;
  display: block;
  margin: auto;

}

a.lp3-pdflink, a.lp3-templatelink, a.lp3-downloadlink {
  @extend .lp3-textlink;

  &:before {
    font-family: fontello;
    font-size: 1.5em;
    vertical-align: middle;
    text-decoration: none;
    display: inline-block;
    padding-right: 0.25rem;
  }
}

a.lp3-pdflink {
  &:before {
    content: '\e817';
    color: #ed1c24;
  }
}

a.lp3-templatelink {
  &:before {
    content: '\e85d';
  }
}

a.lp3-downloadlink {
  &:before {
    content: '\e814';
    font-size: 1.25em;
  }
}

.icon-ok.green-icon:before {
  color: darkgreen;
  font-size: 0.8em;
  margin-right: 0.5em;
}

.lp3-infobox, .lp3-tippbox, .lp3-fazitbox {
  border: 1px dotted $blue;
  background-color: white;
  padding: 1em !important;
  margin: 2em;
  line-height: 1.5em !important;
  text-align: justify;
  hypens: auto;

  @media(max-width: 767px) {
    margin: 0;
  }

  &:before {
    font-family: fontello;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: $blue;
    padding-right: 10px;
    padding-left: 5px;
    margin: -5px 0 0 -5px;
    float: left;
  }

}

.lp3-fazitbox:before {
  content: '\e831';
}

.lp3-infobox:before {
  content: '\e823';
}

.lp3-tippbox:before {
  content: '\e853';
}

.lp3-vorlage {
  position: relative;
  border: 1px dotted #199900;
  background-color: #F4FFF2;
  padding: 1rem;
  padding-bottom: .5rem;
  color: #199900;
  display: flex;

  &:before {
    font-family: fontello;
    color: #199900;
    font-size: 2.5rem;
    line-height: 2.5rem;
    content: '\e85d';
    //text-shadow:1px 1px 0 rgba(0,0,0,.2);
    padding-top: .9rem;
    padding-right: .6em;
    padding-left: 5px;
    margin: -5px 0 0 -5px;
    float: left;
  }

  &:after {
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 0 .3em;
    background: #199900;
    content: 'VORLAGE';
    color: #F4FFF2;
    font-size: .75rem;
    line-height: 1.25rem;
    letter-spacing: .2em;
    font-style: italic;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
  }

  span:first-child {
    flex: 1;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  span:last-child {
    flex: 1;
  }

  a {
    display: inline-block;
    float: right;
    margin-left: 1em;
    padding: .5em 1em;
    margin-bottom: 5px;
  }
}

#lamacms-body .lp3-vorlagen-block {
  padding: 0;
  margin: 1em 0;
  line-height: 1.5em !important;

  .lp3-vorlage1 {
    background-color: white;
    color: $txt-grey;
    position: relative;
    margin: 1em 0;
    padding: 0.5rem 1rem;
    vertical-align: top;
    border: 1px solid $lightgrey;

    h3 {
      font-size: 1.3rem;
      line-height: 1.5rem;
      margin: 0;
      padding: 0;

      &:before {
        font-family: fontello;
        content: '\e85d';
        padding-right: 0.25em;
      }
    }

    span {
      padding-top: 0.5rem;
      display: block;

      a {
        display: inline-block;
        text-decoration: none;

        &:hover {
          text-decoration: underline;

          &:before {
            text-decoration: none;
          }
        }
      }
    }
  }

  h2 {
    font-size: 2em;
    line-height: 1em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.mab {
    h2, h3 {
      color: $orange;
    }

    span a {
      color: $blue;
    }

  }

  &.cus {
    h2, h3 {
      color: $green;
    }

    span a {
      color: $blue;
    }
  }

  &.edu {
    h2, h3 {
      color: #996000;
    }

    span a {
      color: $blue;
    }

  }

  &.ev {
    h2, h3 {
      color: #99007C;
    }

    span a {
      color: $blue;
    }
  }
}

/*@media (min-width: 768px) {
	#lamacms-body .lp3-vorlagen-block .lp3-vorlage1{ width:45%; float:left; margin:2%; }
}*/

ul.lp3-checklist {
  list-style-type: none !important;
}

ul.lp3-checklist > li:before {
  font-family: fontello;
  content: '\e825';
  padding-right: 0.6rem;
  font-size: 0.8em;
  color: darkgreen;
}

.lp3-video {
  border: 1px dotted $blue;
  background-color: white;
  position: relative;

  &:before {
    float: right;
    padding: 0 .3em;
    margin-top: -1px;
    margin-right: -1px;

    background: $blue;
    content: 'HILFE-VIDEO';
    color: white;
    font-size: .75rem;
    line-height: 1.25rem;
    letter-spacing: .2em;
    font-style: italic;
    box-shadow: -1px 1px 2px rgba(0, 0, 0, .3);
  }

  h3 {
    margin: .5rem 1rem;
    padding: 0;
    margin-bottom: 0;

    &:before {
      font-family: fontello;
      content: '\e83e';
      font-size: 1.1em;
      padding-right: .1em;
    }
  }

  .wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    display: block;
    margin: 1rem;
    margin-top: .5rem;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .desc {
    margin-left: 1rem;
    margin-bottom: .5rem;
    color: #666;
    font-size: 0.9rem;
    line-height: 1rem;
    display: block;
  }

  .desc strong {
    color: $blue;
  }
}

.lp3-video-1 {
  max-width: 600px;
  display: block;
  margin: auto;
  margin-bottom: 1rem;

  .wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    display: block;

    margin-top: .5rem;

    .dsgvoOverlay, iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .dsgvoOverlay {
      background: #333;
      color: white;
      z-index: 9;
      border: 1px solid #ddd;
      text-align: center;

      h3 {
        color: white !important;
        margin: 0.5rem auto;

        .icon-video {
          color: red;
          //display: block;
          //position: absolute;
          //font-size: 4rem;
          //top: 0;
        }
      }

      p {
        text-align: center;
      }

      .extVidBtn {
        width: 90%;
      }

      &:before {

      }
    }

    .deactivate {
      position: absolute;
      display: block;
      bottom: -1.5rem;
    }


  }
}

.iframe iframe {
  border: 1px dotted $blue;
}

.lp3-smalltext {
  line-height: 1em;
  font-size: 0.95em;
  text-align: justify;
  color: $txt-grey;
}

.lp-pi {
  line-height: 1.5rem;
  color: $txt-grey;

  .grid-block {
    & > div {
      height: 24em;
    }
  }
}

.lp-pi ul {
  list-style-type: disc;
}

@media (max-width: 767px) {
  .lp-pi {
    margin: 0 0.5rem;

    p {
      padding: 0;
      text-align: justify;
      @include hyphens(auto);

      &.center {
        text-align: center;
      }

    }

    ul {
      padding: 0;

      li {
        text-align: justify;
        @include hyphens(auto);
      }
    }

  }
}

.lp-pi li {
  margin-left: 2em;

  > * {
    vertical-align: top;
  }
}

ul.image-list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    padding: 0 1em;
  }

  li {
    margin: 0;
    text-align: center;
    flex: 1;

    img {
      max-width: 100%;
    }

    @media (max-width: 767px) {
      flex: 50%;
    }

  }
}

em {
  font-style: italic;
}

hr {
  border: 0;
  border-top: 1px dotted $blue;
  padding: 0;
  margin: 0;
}

/* spezifische elemente */
/* content */
#lamacms-body {
  height: 100%;
  background: $almostwhite;

  h1, h2, h3, h4 {
    color: $almostblack;
    font-weight: normal;
    font-family: Ubuntu, 'Open Sans', Arial, sans-serif;

    & + .headline-subtitle {
      font-weight: normal;
      text-align: center;
      @include hyphens(auto);
      font-size: 1.1em;
    }
  }

  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    @media (max-width: 991px) {
      font-size: 2.2rem;
    }
    @media (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  h2 {
    font-size: 2rem;
    line-height: 1.3em;
  }

  h3 {
    font-size: 1.2rem;
    line-height: 1.3em;
  }

  h2, h3, h4 {
    &.cap {
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: normal;

      a {
        text-decoration: none;
        color: $blue;
      }
    }
  }

  h2.cap {
    margin-top: 0;
  }

  h4.cap {
    margin-bottom: 0;
    text-shadow: none;
    font-size: 1rem;
  }

  .lp-pi li {
    margin-bottom: 6px;
  }

  #leftColumn .lp-pi,
  .rightColumn .lp-pi,
  .overlay-box {
    border: 1px solid $lightgrey;
    color: $txt-grey;
    background-color: white;

    text-align: center;

    ul.lp3-checklist {
      margin: auto;
      display: inline-block;
      text-align: left;
    }
  }

  #leftColumn,
  .rightColumn {
    .lp-pi {
      margin: 1em 0 0 0;
      padding: 0.5em;
    }
  }

  a img {
    border-color: $blue;
  }

}

.overlay-box, #leftColumn, .rightColumn {
  .lp-pi {
    ul, li {
      padding: 0;
      margin: 0;
    }
  }
}

#rightTopBoxHead {
  //@extend .lp3-btn-blue;
  background-color: $blue;
  border: 1px solid $blue;
  //border-right-color:#4d7493;
  border-bottom: 0;
  border-top: 0;
  cursor: default;
  margin-top: -2.5rem;
  padding: 0.5rem 0;

  .lp-pi {
    font-weight: bold;
    font-size: 1.1rem;
    color: #fff;

    p {
      margin: 0;
    }
  }
}

#rightTopBox {
  border-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

@media(max-width: 400px) {
  #right-top-box {
    margin: 30px 0;
  }
  #rightTopBoxHead {
    margin-top: 0;
  }
}

@media(max-width: 767px) {
  #right-top-box {
    margin: 30px 0;
  }
}

@media(max-width: 991px) {
  #right-top-box {
  }
}

/* header */
#lamacms-innercenter .head-bar, #lamacms-innercenter .noCookies-infoBar {
  position: absolute !important; /* for nice display in cms-backend */
}

/* footer */

#lp3-ssl-secured iframe {
  width: 115px;
  height: 55px;
}

.lp3-ref-label {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.ref-list {
  list-style-type: none;
  text-align: center;
  margin: 0 auto;
  padding: 0;

  li {
    margin: 0 5px;
    display: inline-block;
    width: 180px;
    height: 100px;
    background: #eaeaea center center no-repeat;
    border: 1px solid #ddd;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);

    filter: gray; /* IE 6-9 */
    background-size: 80%;
    @media (max-width: 767px) {
      width: 150px;
    }
  }
}

.bottom-container .ref-list li {
  background-color: #fafafa;
  background-position: center center;
  background-repeat: no-repeat;
}

$imgpath: '/thm/lp3-common/img';

#amnesty {
  background-image: url('#{$imgpath}/comp/3-amnesty.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-amnesty.svg');
}

#aok {
  background-image: url('#{$imgpath}/comp/3-aok.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-aok.svg');
}

#asb {
  background-image: url('#{$imgpath}/comp/3-asb.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-asb.png');
}

#at-kearney {
  background-image: url('#{$imgpath}/comp/3-at-kearney.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-at-kearney.svg');
}

#basf {
  background-image: url('#{$imgpath}/comp/3-basf.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-basf.svg');
}

#berendsen {
  background-image: url('#{$imgpath}/comp/3-berendsen.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-berendsen.svg');
}

#bmw {
  background-image: url('#{$imgpath}/comp/3-bmw.svg.png');
  background-size: 40%;
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-bmw.svg');
}

#bugatti {
  background-image: url('#{$imgpath}/comp/3-bugatti.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-bugatti.svg');
}

#cebit {
  background-image: url('#{$imgpath}/comp/3-cebit.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-cebit.svg');
}

#caritas {
  background-image: url('#{$imgpath}/comp/3-caritas.png');
}

#coca-cola {
  background-image: url('#{$imgpath}/comp/3-coca-cola.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-coca-cola.svg');
}

#dachser {
  background-image: url('#{$imgpath}/comp/3-dachser.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-dachser.svg');
}

#db {
  background-image: url('#{$imgpath}/comp/3-db.svg.png');
  background-size: 50%;
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-db.svg');
}

#deloitte {
  background-image: url('#{$imgpath}/comp/3-deloitte.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-deloitte.svg');
}

#dena {
  background-image: url('#{$imgpath}/comp/3-dena.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-dena.svg');
}

#ditsch {
  background-image: url('#{$imgpath}/comp/3-ditsch.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-ditsch.svg');
}

#dkms {
  background-image: url('#{$imgpath}/comp/3-dkms.png');
}

#duden {
  background-image: url('#{$imgpath}/comp/3-duden.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-duden.svg');
}

#edeka-sw {
  background-image: url('#{$imgpath}/comp/3-edeka-sw5.png');
}

#enbw {
  background-image: url('#{$imgpath}/comp/3-enbw.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-enbw.svg');
}

#engel-voelkers {
  background-image: url('#{$imgpath}/comp/3-engel-voelkers.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-engel-voelkers.svg');
}

#eon {
  background-image: url('#{$imgpath}/comp/3-eon.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-eon.svg');
}

/*#fedex{ background-image: url('#{$imgpath}/comp/3-fedex.svg.png'); background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-fedex.svg'); }*/
#fujitsu {
  background-image: url('#{$imgpath}/comp/3-fujitsu.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-fujitsu.svg');
}

#gasag {
  background-image: url('#{$imgpath}/comp/3-gasag.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-gasag.svg');
}

#hapaglloyd {
  background-image: url('#{$imgpath}/comp/3-hapag-lloyd.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-hapag-lloyd.svg');
}

#hochland {
  background-image: url('#{$imgpath}/comp/3-hochland.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-hochland.svg');
}

#ibm {
  background-image: url('#{$imgpath}/comp/3-ibm.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-ibm.svg');
}

#idealo {
  background-image: url('#{$imgpath}/comp/3-idealo.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-idealo.svg');
}

#immonet {
  background-image: url('#{$imgpath}/comp/3-immonet.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-immonet.svg');
}

#innogy {
  background-image: url('#{$imgpath}/comp/3-innogy.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-innogy.svg');
}

#jaegermeister {
  background-image: url('#{$imgpath}/comp/3-jaegermeister.svg.png');
}

#kaercher {
  background-image: url('#{$imgpath}/comp/3-kaercher.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-kaercher.svg');
}

#krombacher {
  background-image: url('#{$imgpath}/comp/3-krombacher.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-krombacher.svg');
}

#netze-bw {
  background-image: url('#{$imgpath}/comp/3-netze-bw.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-netze-bw.svg');
}


#otis {
  background-image: url('#{$imgpath}/comp/3-otis.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-otis.svg');
}

#langenscheidt {
  background-image: url('#{$imgpath}/comp/3-langenscheidt.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-langenscheidt.svg');
}

#lotto-hessen {
  background-image: url('#{$imgpath}/comp/3-lotto-hessen.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-lotto-hessen.svg');
}

#nokia {
  background-image: url('#{$imgpath}/comp/3-nokia.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-nokia.svg');
}

#peek {
  background-image: url('#{$imgpath}/comp/3-peekundclop.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-peekundclop.svg');
}

#paulaner {
  background-image: url('#{$imgpath}/comp/3-paulaner.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-paulaner.svg');
}

#pin {
  background-image: url('#{$imgpath}/comp/3-pin.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-pin.svg');
}

/*#postbank{ background-image: url('#{$imgpath}/comp/3-postbank.svg.png'); background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-postbank.svg'); }*/
#siemens {
  background-image: url('#{$imgpath}/comp/3-siemens.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-siemens.svg');
}

#sparkasse-taunus {
  background-image: url('#{$imgpath}/comp/3-sparkasse-taunus.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-sparkasse-taunus.svg');
}

#strabag {
  background-image: url('#{$imgpath}/comp/3-strabag.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-strabag.svg');
}

#taz {
  background-image: url('#{$imgpath}/comp/3-taz.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-taz.svg');
}

#telekom {
  background-image: url('#{$imgpath}/comp/3-telekom.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-telekom.svg');
}

#total {
  background-image: url('#{$imgpath}/comp/3-total-1.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-total-1.svg');
}

#tuev {
  background-image: url('#{$imgpath}/comp/3-tuev.svg.png');
  background-position: center 30%;
  filter: none;
  -webkit-filter: none;
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-tuev.svg');
}

#tui {
  background-image: url('#{$imgpath}/comp/3-tui.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-tui.svg');
}

#unicredit {
  background-image: url('#{$imgpath}/comp/3-unicredit.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-unicredit.svg');
}

#vodafone {
  background-image: url('#{$imgpath}/comp/3-vodafone.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-vodafone.svg');
}

#voss {
  background-image: url('#{$imgpath}/comp/3-voss.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-voss.svg');
}

#wabco {
  background-image: url('#{$imgpath}/comp/3-wabco.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-wabco.svg');
}

#zalando {
  background-image: url('#{$imgpath}/comp/3-zalando.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-zalando.svg');
}

#post {
  background-image: url('#{$imgpath}/comp/3-deutsche-post.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-deutsche-post.svg');
}

#viessmann {
  background-image: url('#{$imgpath}/comp/3-viessmann.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-viessmann.svg');
}

#yellow {
  background-image: url('#{$imgpath}/comp/3-yellow.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-yellow.svg');
}

#dlr-dz {
  background-image: url('#{$imgpath}/comp/3-dlr.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-dlr.png');
}

#zvei {
  background-image: url('#{$imgpath}/comp/3-zvei.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-zvei.svg');
}

#zwilling {
  background-image: url('#{$imgpath}/comp/3-zwilling.svg.png');
  background-image: linear-gradient(transparent, transparent), url('#{$imgpath}/comp/3-zwilling.svg');
}

#heise {
  background-image: url('#{$imgpath}/press/3-heise.svg');
}

#waz {
  background-image: url('#{$imgpath}/press/3-waz.svg');
}

#focus {
  background-image: url('#{$imgpath}/2-focus.png');
}

#computerwoche {
  background-image: url('#{$imgpath}/press/3-computerwoche.svg');
}

#handelsblatt {
  background-image: url('#{$imgpath}/press/3-handelsblatt.svg');
}

#herta {
  background-image: url('#{$imgpath}/comp/herta.png');
}


//NGO-Logos
#amnesty {
  background-image: url('#{$imgpath}/ngo/amnesty.jpg');
}

#advd {
  background-image: url('#{$imgpath}/ngo/advd.png');
}

#anu {
  background-image: url('#{$imgpath}/ngo/anu.png');
}

#apo {
  background-image: url('#{$imgpath}/ngo/apo.png');
}

#blaues-kreuz {
  background-image: url('#{$imgpath}/ngo/blaues-kreuz.PNG');
}

#benevol {
  background-image: url('#{$imgpath}/ngo/benevol.png');
}

#bit {
  background-image: url('#{$imgpath}/ngo/bit.png');
}

#ccvd {
  background-image: url('#{$imgpath}/ngo/ccvd.png');
}

#changing-cities {
  background-image: url('#{$imgpath}/ngo/changing-cities.svg');
}

#dguht {
  background-image: url('#{$imgpath}/ngo/dguht.png');
}

#ht {
  background-image: url('#{$imgpath}/ngo/ht.png');
}

#jumpp {
  background-image: url('#{$imgpath}/ngo/jumpp.jpg');
}

#juuuport {
  background-image: url('#{$imgpath}/ngo/juuuport.png');
}

#kaen-guruh {
  background-image: url('#{$imgpath}/ngo/kaen-guruh.png');
}

#lafim {
  background-image: url('#{$imgpath}/ngo/lafim-diakonie.jpg');
}

#spitex {
  background-image: url('#{$imgpath}/ngo/spitex-basel.png');
}

#sss {
  background-image: url('#{$imgpath}/ngo/sss.png');
}

#tvja {
  background-image: url('#{$imgpath}/ngo/tvja.jpg');
}

#villa-fohrde {
  background-image: url('#{$imgpath}/ngo/villa-fohrde.png');
}

#zgm {
  background-image: url('#{$imgpath}/ngo/zgm.png');
}

#perspektive {
  background-image: url('#{$imgpath}/ngo/perspektive.png');
}

#qse {
  background-image: url('#{$imgpath}/ngo/querstadtein.png');
}

#khn {
  background-image: url('#{$imgpath}/ngo/khn.png');
}

#kok {
  background-image: url('#{$imgpath}/ngo/kok.jpg');
}

#mannheimer {
  background-image: url('#{$imgpath}/ngo/mannheimer-philharmoniker.gif');
}

#dafv {
  background-image: url('#{$imgpath}/ngo/dafv.png');
}

#tdf {
  background-image: url('#{$imgpath}/ngo/tdf.png');
}

#wind-schatten {
  background-image: url('#{$imgpath}/ngo/wind-schatten.png');
}


#westfalenfleiss {
  background-image: url('#{$imgpath}/ngo/westfalenfleiss.jpg');
}

#dlr {
  background: url('#{$imgpath}/uni/3-dlr.svg') no-repeat;
  display: inline-block;
  width: 70px;
  height: 60px;
  margin: auto;
  background-size: 100%;
  margin-right: 20px;
}

#dfki {
  background: url('#{$imgpath}/uni/3-dfki.svg') no-repeat;
  display: inline-block;
  width: 290px;
  height: 60px;
  margin: auto;
  background-size: 100%;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE 6-9 */
}

#weconomy {
  background: url('#{$imgpath}/weconomy-2012-small.png') no-repeat;
  display: block;
  width: 133px;
  height: 53px;
  margin: auto;
}

#ssl {
  background: url('#{$imgpath}/ssl-valid-en-m.png') no-repeat;
  display: block;
  width: 140px;
  height: 67px;
  margin: auto;
}

/* Background when selecting text */
::selection {
  background: #ffd65e;
}

ul.treenav {
  list-style-type: none;
  @media(max-width: 767px) {
    .treenav {
      padding-left: 10px;
    }
  }

  li.hasSubtree {
    i {
      cursor: pointer;
    }

    &:hover > i {
      color: orange;
    }

    ul.treenav {
      display: none;
    }

    &.activeParent ul.treenav, &.active > ul.treenav {
      display: block;
    }
  }
}

#lamacms-body .foot-bar {
  font-size: 13px;

  .foot-nav-item {
    color: white;
    margin: 0.3em auto;
    font-size: 1.3em;
    font-weight: normal;
  }

  hr {
    border-top: 1px solid $grey;
  }

  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media(max-width: 768px) {
      justify-content: left;
    }

    ul {
      width: 12em;
      margin: 1em 5em;
      //margin: 0.5em 1.2em 1em;
      padding: 0;
      list-style-type: none;

      a {
        color: white;

        &:after {
          border-bottom: 1px solid white;
        }
      }

    }
  }
}

#sec-footer-imgs {
  text-align: center;

  img {
    display: inline-block;
    height: 125px;
    width: auto;
    margin-left: 1rem;
  }
}

.xmpl-block {
  display: inline-block;
  width: 40%;
  max-width: 200px;
  border: 1px dotted $blue;
  text-align: center;
  background-color: #f0f0f0;

  margin-right: 20px;
  margin-bottom: 20px;
}

#lamacms-body .xmpl-block h2 {
  font-size: 1.3em;
}

img.xmpl {
  border: 2px solid $blue;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.xmpl-block a.lp3-btn-blue {
  padding: 0 10px;
  margin: 20px auto;
  display: inline-block;
  line-height: 2rem;
}

.lp3-deco-icon-1 {
  font-size: 4em;
  line-height: 1.3em;
  color: $txt-grey;
}

.tryIt {
  position: relative;
  display: inline-block;
  padding: 20px 50px;
  margin-top: 100px;
  margin-bottom: 50px;
  font-size: 1.5em;
  line-height: 1em;
  text-align: center;

  &:before {
    position: absolute;
    display: block;
    top: -60px;
    left: -170px;
    width: 150px;
    height: 111px;
    background-image: url('../../lp3-common/img/tryit-de.svg');
    background-size: 100% 100%;
    content: ' ';
    @media(max-width: 767px) {
      left: 30px;
      top: -100px;
    }
  }

  &:after {
    font-family: fontello;
    content: '\e815';
    margin-left: .4em;
  }

  @media(max-width: 767px) {
    margin: 100px 0 0 0;
    padding: 1rem 2rem;
  }

}

/* big green button with written text "muster und vorlagen" */
.lp3-muster-link {
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  margin-top: 80px;
  font-size: 1.4em;
  line-height: 1em;
  text-align: center;

  &:before {
    position: absolute;
    display: block;
    top: -80px;
    left: -70px;
    width: 150px;
    height: 68px;
    background-image: url('../../lp3-common/img/muster-text.svg');
    background-size: 100% 100%;
    content: ' ';
  }

  &:after {
    font-family: fontello;
    content: '\e815';
    margin-left: .4em;
  }
}

@media(max-width: 400px) {
  .lp3-muster-link {
    display: block;
    margin: auto;
    margin-top: 60px;
  }
  .lp3-muster-link:before {
    left: 30px;
  }
}

/* big green button with written text "muster und vorlagen" */

/* image fader / swaps images in a position - js-dependend */
.img-slider {
  position: relative;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    &:last-child {
      position: static;
    }

    @include transition-duration(1s);

    &.hiddn {
      opacity: 0;
    }
  }
}

/* image fader / swaps images in a position - js-dependend */

/* flipping cards - js-dependend */
.flipcard {
  @include perspective(1000);

  -ms-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;

  &.flipped:hover .card, &.flipped .card {
    .back {
      @include rotateY(0deg);
    }

    // for IE
    .front {
      @include rotateY(180deg);
    }

    // for IE
  }

  .card {
    @include transition(1s);
    @include transform-style(preserve-3d);
    -ms-transform: perspective(1000px);
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;

    position: relative;

    .back, .front {
      @include backface-visibility(hidden);
      @include transition(1s);
      @include transform-style(preserve-3d);

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .front {
      z-index: 2;
      @include rotateY(0deg);
    }

    .back {
      @include rotateY(-180deg);
    }
  }
}

.ref-logo {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 1em;

  .card {
    width: 100%;
    padding-top: 55.5%;

    .back, .front {
      background: white center center no-repeat;
      background-size: 80%;
      //@include outset-border(1px, solid, $blue);
      border: 1px solid $lightgrey;

      &:after {
        content: 'LamaPoll Kunde';
        position: absolute;
        bottom: 0.3em;
        right: 0.3em;
        text-shadow: none;
        font-size: 0.8em;
        font-style: italic;
        color: #888;
      }
    }
  }
}

/* flipping cards - js-dependend */

/* topic tree / link list */
.topic-tree-container-de, .topic-tree-container-en,
.linklist-container-de, .linklist-container-en {
  background: #fff;
  border-top: 1px dotted $lightgrey;
  border-bottom: 1px dotted $lightgrey;
  padding: 1.5em;
  color: $blue;

  a {
    @extend .lp3-textlink;
    line-height: 1.65rem;
  }

  &:before {
    font-weight: bold;
    color: #606060;
    display: block;
    margin-bottom: 1em;
  }
}

.linklist-container-de:before {
  content: 'Inhaltsverzeichnis';
}

.linklist-container-en:before {
  content: 'Contents';
}

.topic-tree-container-de, .topic-tree-container-en {
  .hasSubtree > i {
    font-size: 1.3rem;
    vertical-align: middle;
  }

  a {

    &.active {
      font-weight: bold;
    }

    &.activeParent {
      font-style: italic;
    }
  }
}

.topic-tree-container-de:before {
  content: 'Mehr zum Thema';
}

.topic-tree-container-en:before {
  content: 'More about this topic';
}

/* topic tree / link list */

/* review */
.review-box {
  width: 100%;
  position: relative;

  .review-author {
    width: 30%;
    font-style: italic;
    font-size: 0.9em;
    line-height: 1.4em;
  }

  .review-image {
    display: inline-block;
    vertical-align: top;
    padding-top: 10px;
    padding-bottom: 5px;

    a {
      display: inline-block;
      width: 85%;
      vertical-align: top;
      text-decoration: none; // border: 1px solid $blue;
      @include border-radius(50%);
      @include box-shadow(0 0 4px rgba(0, 0, 0, .5));
      border: 10px solid white;

      img {
        width: 100%;
        height: auto;
        display: block;
        @include border-radius(50%);
      }
    }

  }

  .review-text {
    width: 70%;
    position: absolute;
    right: 0;
  }

  .review-rating {
    position: absolute;
    right: 10px;
    bottom: 0;
  }
}

.review-box-1 {
  h1 {
    margin-bottom: 0em;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 2em;
  }

  .review-author {
    text-align: center;
    font-size: 1.25em;
    line-height: 1.2em;
    margin-bottom: 0.5em;

    .review-image {
      display: inline-block;
      width: 85%;
      vertical-align: top;
      @include border-radius(50%);
      @include box-shadow(0 0 4px rgba(0, 0, 0, .5));
      border: 10px solid white;

      img {
        width: 100%;
        height: auto;
        display: block;
        @include border-radius(50%);
      }

      margin-bottom: 1em;
      @media(min-width: 768px) {
        margin-bottom: 0;
      }
    }

    .author-name, .company {
      font-weight: bold;
    }

    .job-title {
      font-size: 0.75rem;
      font-style: italic;
    }

    @media(min-width: 768px) {
      text-align: left;
      .author-name, .company {
        font-weight: bold;
      }
    }
  }

  .review-text {
    font-size: 1.25em;
    text-align: justify;
    @include hyphens(auto);
    @media(min-width: 768px) {
      text-align: left;
    }
  }

  .review-rating {
    text-align: right;
  }
}

.review-box-2 {
  .author-image {
    width: 50%;
    @media(min-width: 768px) {
      width: 25%;
    }
    @media(min-width: 1200px) {
      width: 12%;
    }
    margin: auto;
    border: 10px solid white;
    @include border-radius(20px);
    @include box-shadow(0 0 4px rgba(0, 0, 0, .5));

    img {
      width: 100%;
      height: auto;
      display: block;
      @include border-radius(10px);
    }
  }

  .author-image-landscape {
    @extend .author-image;
    @media(min-width: 768px) {
      width: 35%;
    }
    @media(min-width: 1200px) {
      width: 22%;
    }
  }

  .author-info {
    text-align: center;

    .author-name, .company {
      font-weight: bold;
      font-size: 1.1em;
    }

    .job-title {
      display: block;
      font-style: italic;
      font-size: 0.9em;
    }
  }

  .review-quote {
    text-align: justify;
    @include hyphens(auto);
    font-size: 1.1em;
    margin: auto;
    padding: 0 1rem;
    @media(min-width: 768px) {
      width: 80%;
    }
  }

  .author-image + .review-rating, .author-image-landscape + .review-rating {
    width: 5rem;
    background: white;
    margin-top: -2px;
    margin-bottom: 1em;
    padding: 5px;
    padding-bottom: 2px;
    @include border-radius-bottom(10px);
    @include box-shadow(0 1px 1px rgba(0, 0, 0, .5));

    .numeric {
      display: block;
    }
  }

  .review-rating {
    text-align: center;
    margin: auto;
    display: block;
    line-height: 100%;

    img {
      width: 1em;
      height: auto;
    }

    .numeric {
      font-weight: bold;
      font-size: 0.75em;
    }
  }

  .review-label {
    text-align: center;
    color: #a0a0a0;
    display: block;
    font-style: italic;
    font-size: 0.75em;
  }

  margin: 0.5em 0;
}

.overlay-box .review-box-2, .rightColumn .review-box-2 {
  line-height: 1.25em;

  .author-image, .author-image-landscape {
    @media(min-width: 768px) {
      width: 70%;
    }
  }

  .review-label {
    margin-top: 0;
  }

  .review-quote {
    font-size: 0.9em;
    padding: 0 0.25em;
    @media(min-width: 768px) {
      width: 100%;
    }
  }

  &.no-image {
    .review-label, .review-quote, .review-rating, .author-info {
      margin: 0 0.25em;
      text-align: left;
    }

    .review-label {
      display: none;
    }
  }
}

/* review */

/* example-block */
.xmpl-block1 {
  h2 {
    margin-bottom: 0;
    border-bottom: 1px dotted $txt-grey;
    text-transform: uppercase;
    font-weight: normal;

    > span {
      display: block;
      text-transform: none;
      font-size: 1rem;
      margin: 0 1em;
      line-height: 1.3em;

      &:before {
        color: darkgreen;
        font-size: 0.8em;
        margin-right: 0.5em;
      }

      @media (min-width: 768px) {
        display: inline;
        line-height: 1.3em;
      }
    }
  }

  .box {
    //margin:1em;
    position: relative;
    //border-bottom:2px solid $blue;
    display: block;
    text-decoration: none;
    line-height: 1.5rem;

    img {
      width: 90%;
      margin: 5%;
      display: block;
      box-sizing: border-box;
      position: relative;
      top: 2em;
      @include transition('top 0.25s');
    }

    .head {
      display: block;
      line-height: 2rem;
      color: $blue;
      border-top: 1px solid $blue;
      background: #f9f9f9;
      font-size: 1.2em;
      text-align: center;
      margin-top: -2em;
      height: 2em;
      position: relative;
      //text-shadow:1px 1px 2px rgba(0,0,0,0.3);
      vertical-align: middle;
      text-transform: uppercase;
      letter-spacing: 0.25em;
      @include transition('background-color 0.25s');
      z-index: 2;
    }

    .text {
      display: block;
      position: relative;
      text-decoration: none;
      background: #f9f9f9;
      //border:1px dotted transparent;
      border-top: 0;
      color: #333;
      z-index: 2;
      //@include transition('border-color 0.25s');
    }

    &:hover {
      img {
        top: 0;
      }

      .head {
        color: white;
        background-color: $blue;
      }

      .text {
        //color:$orange;
      }
    }
  }
}

/* example-block */
