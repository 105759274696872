$blue:#2185D0;
$green:#44aa22;
$orange:#e69900;
$grey:#737373;
$almostblack:#333;
$txt-grey: darken($grey, 5%); /* #666666 */
$lightgrey: #cccccc;
$almostwhite:#f9f9f9;
$lightgreen:#9ae887;



/* font-colors */
.darkgrey	{ color:#666666; }
.grey		{ color:#999999; }
.lightgrey	{ color:#eeeeee; }
.lightergrey{ color:#f0f0f0; }
.blue		{ color:$blue; }
.blue2		{ color:$blue; }
.white		{ color:#ffffff; }
.red		{ color:#fd6318; }
.orange		{ color:$orange; }
.green		{ color:#9ae887; }
.darkgreen  { color:#87cc76; }

/* background-colors */
.bg-white	 { background-color:#ffffff; }
.bg-dirtwhite{ background-color:#fafafa; }
.bg-lighter	 { background-color:#f6f6f6; }
.bg-light	 { background-color:#f0f0f0; }
.bg-blue	 { background-color:$blue; }
.bg-orange   { background-color:$orange; }
.bg-green    { background-color:#9ae887; }
.bg-red      { background-color:#fd6318; }

