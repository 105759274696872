input[type=checkbox]{
	@include box-shadow(none);
	border:0;
	vertical-align: middle;
	margin:0;
	margin-right:2px;
}

$checksize:1.3em;
.gt-ie8 .styled-checkbox{
	position: relative;
	min-height: #{($checksize)};
	margin-top:1rem;
	&:hover input{
		+ label:before,
		&:checked + label:before{

		}
		+ label:after{ @include opacity(40); color:$lightgrey; }
		&:checked + label:after{ @include opacity(100); color:$txt-grey; }
		
	}

	input {
		visibility: hidden;
		position:absolute;
		
		&:checked + label:before{
			background:white;
			@include box-shadow("inset 0px 1px 0 rgba(255,255,255,0.5)");
		}
		
		&:checked + label:after {
			@include opacity(100);
		}
	}
	
	label{
		cursor: pointer;
		padding-left:#{($checksize)+0.3};
		line-height:#{($checksize)*1.05};
		
		display:inline-block;
		
		&:before{
			position: absolute; width: $checksize; height: $checksize; top:0; left:0;
			border:1px solid $lightgrey;
			content: '';
			background: #ffffff;
		}
		
		&:after {
			position: absolute; top:0; left:0;
			@include opacity(0);
			text-align:center;
			width:$checksize;
			line-height:$checksize;
			vertical-align:middle;
			font-family: fontello;
			color:$txt-grey;

			content: '\e826';
		}
	}
}