@charset "UTF-8";
@import "../../lp3-common/scss/colors";
@import "../../lp3-common/scss/mixins";
@import "../../lp3-common/scss/fonts";

/*@import url(https://fonts.googleapis.com/css?family=Droid+Sans:400,700);*/
/*@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800);*/

.font, .font textarea, .font table { font-family: 'Open Sans', Arial, sans-serif; font-size:14px; color: $txt-grey; }
@media (max-width: 768px) { .font, .font textarea, .font table {font-size:14px; } }



/* borders */
.no-border{ border:0; }
.std-border{ border:1px solid $lightgrey;  }
.dot-border{ border:1px dotted $blue; }
.fat-border{ border:6px solid rgba(121,183,231,0.25); }
.grey-border{ border:1px solid #e0e0e0; }
.darkgrey-border{ border:1px solid #c0c0c0; }

.dot-border-tb{ border:1px dotted $blue; border-left:0; border-right:0; }
.dot-border-t{ border-top:1px dotted $blue; }
.dot-border-b{ border-bottom:1px dotted $blue; }
.dot-border-r{ border-right:1px dotted $blue; }
.dot-border-l{ border-left:1px dotted $blue; }

.blue-border-tb{ border:1px solid $blue; border-left:0; border-right:0; }
.blue-border-t{ border-top:1px solid $blue; }
.blue-border-b{ border-bottom:1px solid $blue; }
.blue-border-r{ border-right:1px solid $blue; }
.blue-border-l{ border-left:1px solid $blue; }


.grey-border-t{ border-top:1px solid #e0e0e0; }
.grey-border-b{ border-bottom:1px solid #e0e0e0; }
.grey-border-r{ border-right:1px solid #e0e0e0; }
.grey-border-l{ border-left:1px solid #e0e0e0; }

.err-border{ border:1px solid #AD3D3D!important; } /* only for error-scenario use! */

/* box-shadows */
.box-shad-5{ box-shadow: 1px 1px 5px rgba(0,0,0,.25); }
.box-shad-5-down{ box-shadow: 0px 3px 5px rgba(0,0,0,.25); }
.box-shad-10{ box-shadow: 1px 1px 10px rgba(0,0,0,.25); }
.box-shad-out{ box-shadow: 0 -1px 1px #fff, 0 1px 1px #999; }
.box-shad-in { box-shadow: 0 -1px 1px #999, 0 1px 1px #fff; }
.box-shad-lr { box-shadow: 2px 0 2px rgba(0, 0, 0, .35), -2px 0 2px rgba(0, 0, 0, .35); }


/* text-shadows */
.txt-shad-out{ text-shadow: 0 -1px 1px #fff, 0 1px 1px #999; }
.txt-shad-in { text-shadow: 0 -1px 1px #999, 0 1px 1px #fff; }
.txt-stamp-w { text-shadow: 0 1px 0 rgba(255,255,255,.75); }
.txt-stamp-b { text-shadow: -1px -1px 0px rgba(29, 99, 166, .50); }
.fat-shad-grey{text-shadow: 1px 1px 0 rgba(0,0,0,.2);}

/* text links / external links icon (right of text) */
a.lp3-ext-link:after{ font-family: fontello; content: ' \e81B'; }
a.lp3-rightarrow:after{ font-family: 'Open Sans', Arial, sans-serif; content: ' »'; }
a.lp3-textlink{
	color:$blue;
	text-decoration:none;
	display:inline-block;
	position: relative;
	cursor: pointer;

	&[disabled], &[disabled]:hover{
		border:0; color:#999; cursor:default!important;
	}
	
	&.white{
		color:#fff;
		border-bottom:1px dotted #fff;
		&:hover{
			border-bottom:0; color:#fff;
		}
	}

	&:not(.lp3-ext-link):not(.lp3-rightarrow):after,
	&.lp3-ext-link:before,
	&.lp3-rightarrow:before {
		display:block;
		content: '';
		border-bottom: 1px solid $blue;
		transform: scaleX(0);
		transition: transform 0.2s ease-in-out;
		position: absolute;
		bottom: 2px;
		width:100%;
	}

	&:hover:not(.lp3-ext-link):not(.lp3-rightarrow):after,
	&.lp3-ext-link:hover:before,
	&.lp3-rightarrow:hover:before{
		transform: scaleX(1);
	}
}

/* lama button styles */
.lp3-btn-orange, .lp3-btn-blue, .lp3-btn-blue2, .lp3-btn-blue3, .lp3-btn-green, .lp3-btn-grey {
	@include transition('all .25s');
	color:#ffffff;
	text-decoration:none!important;
	cursor: pointer;
	border-radius:2em;
	border:0;
	display:inline-block;
	padding: 0.5em 1em;
  &:hover{
	  text-decoration:none;
  }
}


.lp3-btn-grey{
	background-color: $grey; /* Old browsers */
}

.lp3-btn-grey:hover{
	background-color: darken($grey, 5%)
}

a.lp3-btn-grey:active, input[type=button].lp3-btn-grey:active, input[type=submit].lp3-btn-grey:active{
	background: $grey;
	box-shadow:0 1px 0 rgba(255, 255, 255, .5) inset,0 -1px 0 rgba(255, 170, 0, .2) inset;
}


.lp3-btn-orange{
	background-color: $orange; /* Old browsers */
}
.lp3-btn-orange:hover{
	background-color: darken($orange, 5%);
}

a.lp3-btn-orange:active, input[type=button].lp3-btn-orange:active, input[type=submit].lp3-btn-orange:active{
	background: $orange;
    box-shadow:0 1px 0 rgba(255, 255, 255, .5) inset,0 -1px 0 rgba(255, 170, 0, .2) inset;
}

.lp3-btn-blue  { 
	background-color: $blue; /* Old browsers */
}
.lp3-btn-blue:hover{
	background-color: darken($blue, 5%);
}

a.lp3-btn-blue:active, input[type=button].lp3-btn-blue:active, input[type=submit].lp3-btn-blue:active{
	background-color: $blue;
	box-shadow:0 1px 0 rgba(255, 255, 255, .5) inset,0 -1px 0 rgba(40, 149, 204, .2) inset;
}

.lp3-btn-green, input[type=button].lp3-btn-green, input[type=submit].lp3-btn-green{
	background-color:$green;
	&:hover{
		background-color: darken($green, 5%);
	}
	&:active{
		background: $green;
		box-shadow:0 1px 0 rgba(255, 255, 255, .5) inset,0 -1px 0 rgba(81, 204, 40, .2) inset;
	}
}

.lp3-btn-inactive, .lp3-btn-inactive:hover, .lp3-btn-inactive:active{
	color:#ffffff!important;
	border:1px solid #a0a0a0!important;
	background: rgb(229,229,229)!important; /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodFHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U1ZTVlNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjZWNlY2UiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+)!important;
	background: linear-gradient(to bottom,  rgba(229,229,229,1) 0%,rgba(206,206,206,1) 100%)!important; /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#cecece',GradientType=0 )!important; /* IE6-8 */
}

/* combos (hp: box oben rechts mit "Warum LamaPoll") */
.lp3-btn-blue.box-shad-5{ box-shadow:0 1px 0 rgba(255, 255, 255, .5) inset,0 -1px 0 rgba(37, 125, 210, .2) inset, 0 1px 1px rgba(21, 72, 121, .5),1px 1px 6px rgba(0,0,0,.25);}

.reg-button{
	@include transition('all .25s');
	@extend .lp3-btn-green;
	display:inline-block;
	margin-top:1em;
	padding: .5em 1em;
	font-size:1.2em;
	line-height:1.5em;
	@media (min-width: 768px) { font-size:1.5em; padding: .5em 2em; }
	@media (min-width: 992px) { padding: .5em 2em; }
	@media (min-width: 1200px) { padding: .5em 3em; }
	&:hover{
		background-color: darken($green, 5%);
	}
	&:after{
		font-family:fontello;
		content:'\e815';
		padding-left:.4em;
	}
}


.lp3-grey-box1{
	background: rgb(244,244,244); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0ZjRmNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlNWU1ZTUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: linear-gradient(to bottom,  rgba(244,244,244,1) 0%,rgba(229,229,229,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-8 */
	box-shadow:0 1px 0 rgba(255, 255, 255, .5) inset,0 -1px 0 rgba(200, 200, 200, .2) inset, 1px 1px 3px rgba(0, 0, 0, .15);
}

.lp3-grey-box1:hover{
	background: rgb(244,244,244); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0ZjRmNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlZGVkZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: linear-gradient(to bottom,  rgba(244,244,244,1) 0%,rgba(237,237,237,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#ededed',GradientType=0 ); /* IE6-8 */
	box-shadow:0 1px 0 rgba(255, 255, 255, .5) inset,0 -1px 0 rgba(200, 200, 200, .2) inset;
}

.lp3-grey-box-btn{
	background: rgb(244,244,244); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0ZjRmNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlNWU1ZTUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: linear-gradient(to bottom,  rgba(244,244,244,1) 0%,rgba(229,229,229,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-8 */
	box-shadow:1px 1px 1px rgba(255, 255, 255, 1) inset, 1px 1px 3px rgba(0, 0, 0, .10);
	border:1px solid #c0c0c0;
}

.lp3-grey-box-btn:hover{
	background: rgb(255,255,255); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlYWVhZWEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eaeaea',GradientType=0 ); /* IE6-8 */

	box-shadow:1px 1px 1px rgba(255, 255, 255, 1) inset;
}

.lp3-grey-box-btn.inset{
	background:#f9f9f9;
	box-shadow: 1px 1px 3px rgba(0,0,0,.1) inset,-1px -1px 4px rgba(255,255,255,1) inset;
	cursor:default;
}

/* input field marker */
.lp3-inputicon-left, .lp3-inputicon-right{
	vertical-align:middle;
	border:1px solid $blue;
	display:inline-block; box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box;
	color:$blue; text-shadow: 1px 1px 2px rgba(0,0,0,.1);
	cursor:pointer;
	background: rgb(250,250,250); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZhZmFmYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmM2YzZjMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: linear-gradient(to bottom,  rgba(250,250,250,1) 0%,rgba(243,243,243,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#f3f3f3',GradientType=0 ); /* IE6-8 */
}
.lp3-inputicon-left:hover, .lp3-inputicon-right:hover{ color:$orange; }
.lp3-inputicon-left:active, .lp3-inputicon-right:active{ background:#fafafa; }

.lp3-inputicon-left{ border-right-width:0; }
.lp3-inputicon-right{ border-left-width:0; }

/*  unused 
.lp3-text-shadow-1{text-shadow: 1px 1px 0px rgba(0, 0, 0, .50);}

*/

/* background-gradients */
.lp3-grad-grey{
	background: rgb(246,246,246); /* Old browsers */
	background: linear-gradient(to bottom,  rgba(246,246,246,1) 0%,rgba(240,240,240,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#f0f0f0',GradientType=0 ); /* IE6-9 */
}

.lp3-grad-darkgrey{
    background: rgb(240,240,240); /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YwZjBmMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjZjBmMGYwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2UwZTBlMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: linear-gradient(to bottom,  rgba(240,240,240,1) 0%,rgba(240,240,240,1) 0%,rgba(224,224,224,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f0f0', endColorstr='#e0e0e0',GradientType=0 ); /* IE6-8 */

}

/**********************/
/* Style Combinations */
/**********************/
.mandatory{color:$orange;}

/* weitere elemente */
.green-check{ background: url('../../lp3-common/img/pro-arrow-6.png') no-repeat; width:20px; height:20px; }

.placeholder,
::-webkit-input-placeholder,
::-moz-placeholder, /* firefox 19+ */
:-ms-input-placeholder, /* ie */
input:-moz-placeholder { color:#999; font-weight:bolder; font-size:120px; }
.placeholderFocus{color:#000} /* old browsers, reset to normal input */


/***********************/
/* Message/Error Boxen */
/***********************/
.boxicon{
	position: absolute; top:-5px; left:-5px; width:30px; height:30px;
	border-width:2px;
	border-style: solid;
	font-family: comic sans;
	font-size:25px;									/* .fs-30 			*/
	line-height:30px;
	border-radius:50%;								/* .circle 			*/
	-moz-border-radius:50%;
	-webkit-border-radius:50%;
	font-weight:bold;								/* .bold 			*/
	text-align: center;								/* .center 			*/
	text-indent:-2px;
	text-shadow: 0 1px 0 rgba(255,255,255,.75);
	overflow:hidden;
	
	background: rgb(244,244,244); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0ZjRmNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlNWU1ZTUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: linear-gradient(to bottom,  rgba(244,244,244,1) 0%,rgba(229,229,229,1) 100%); /* W3C */
	/* IE6-8 :  filter beachtet den border-radius nicht, sieht nicht gut, der kompletter Hintergrund quadratisch ausgefüllt wird, dann lieber garnicht */
	/* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#e5e5e5',GradientType=0 ); */
	box-shadow:0 1px 0 rgba(255, 255, 255, .5) inset,0 -1px 0 rgba(37, 125, 210, .2) inset, 1px 1px 3px rgba(0, 0, 0, .15);
}

/* IE6-8 :  das kein border-radius, sehen die icon nicht gut aus, dann lieber garnicht */
.lt-ie8 .boxicon { border:0; }

.infobox, .warnbox, .errorbox, .successbox, .infobubble .infobubble-box{
	position:relative;
	border-width:1px;
	border-style:solid;
	text-align:left;							/* .left 				*/
	border-radius:5px;							/* .corner-5 			*/
	padding:10px 10px 10px 40px;				/* .pad-10 .pad-l-40 	*/
	margin-top:10px; margin-bottom:10px;		/* .mag-t-10 .mag-b-10  */
	box-shadow: 1px 1px 5px rgba(0,0,0,.25);	/* .box-shad-5 			*/
	background-color:#fafafa;					/* .bg-dirtwhite 		*/
	font-weight:normal;
	font-size:14px;
}
.infobubble .infobubble-box,
.infobox{ border-color: $blue;}
.infobox .boxicon{ border-color: $blue; color:#5682a4; }

.warnbox{ border-color: #fd9e18; border-right-color:#a56710; border-bottom-color:#a56710;  }
.warnbox .boxicon{ border-color:#fd9e18; border-right-color:#a56710; border-bottom-color:#a56710; color:#e38e16; }

.errorbox{ border-color: #e87979; border-right-color:#944d4d; border-bottom-color:#944d4d; }
.errorbox .boxicon{ border-color: #e87979; border-right-color:#944d4d; border-bottom-color:#944d4d; color:#a35555; }

.successbox{ border-color: #9afd87; border-right-color:#6cb15e; border-bottom-color:#6cb15e; }
.successbox .boxicon{ border-color:#9afd87; border-right-color:#6cb15e; border-bottom-color:#6cb15e; color:#5dcf47;}

.infobubble{ text-decoration: none; position:relative; cursor:pointer; font-size:12pt; }
.infobubble:hover{ color:#fd9e18; }
.infobubble .infobubble-box{
    position:absolute; display:none; top:-15px; left:30px; width:200px; z-index:1;
    font-size:0.8rem; color:#666; background-color: #f0f0f0; line-height:1.4rem;
    padding:5px 10px;
}

.infobubble .infobubble-box:before,
.infobubble .infobubble-box:after{
    content:"";
    display:block;
    position:absolute;
    top:5px;
    left:-21px;
    width:0;
    height:0;
    border:10px solid transparent;
    border-right-color: $blue;
}

.infobubble .infobubble-box:after{
    border-right-color: #f0f0f0;
    left:-20px;
}
