.tooltip{
	position:relative;
	cursor:pointer;
	vertical-align:top;
	//&:before{
	&:after{
		font-family: fontello;
		content:'\e823';
		color:#a0a0a0;
		padding-left:0.35em;
		font-size:1.15em;
		line-height:1rem;
	}

	
	&:hover .text{
      opacity:1;
		//@include transition('opacity 0s linear');
      visibility: visible;

    }

	.text{
		opacity:0;
		text-transform: none;
		visibility:hidden;
		@include transition('opacity 0.25s linear 0.5s, visibility 0s linear 0.75s');
		transition-delay: 0.4s;
		position:absolute; top:-8px; left:30px; width:200px;
		background: white;
		z-index:3;
		text-align:left;
		padding:0.5em;
		line-height:1.5em;
		border:1px solid #c0c0c0;
		@include box-shadow('0 0 4px  rgba(0,0,0,0.25)');
		@include border-radius(4px);
		
		&:before{
			display:block;
			content:'';
			border:11px solid transparent;
			border-right-color: #c0c0c0;
			position:absolute;
			left:-22px; top:3px;
		}
		&:after{
			display:block;
			content:'';
			border:10px solid transparent;
			border-right-color: white;
			position:absolute;
			left:-20px; top:4px;
		}
	}
	&.left-hand .text{
		left:-215px; right:auto;
		&:before{ left:auto; right:-22px; border-color: transparent; border-left-color: #c0c0c0; }
		&:after{ left:auto; right:-20px; border-color: transparent; border-left-color: white; }
	}
}

.line-tooltip{
	@extend .tooltip;
	.text{
		left: auto;
		right: -215px;
	}
}

.bottom-tooltip{
	@extend .tooltip;
	.text{
		top:28px;
		right:-46px;
		left: auto;
		&:before{
			border:11px solid transparent;
			border-bottom-color: #c0c0c0;
			right:41px; top:-22px;
			left: auto;
		}
		&:after{
			border:10px solid transparent;
			border-bottom-color: white;
			right:42px; top:-20px;
			left: auto;
		}
	}
}