input{
	width:auto;
}

.std-input{
	@extend .std-border;
	@include box-sizing(border-box);
	padding: .3em .5em;
	width: 100%;
	background-color:#ffffff;
	//@include box-shadow("0px 1px 1px rgba(0,0,0,.2) inset");
	@include transition('box-shadow 0.1s, border 0.1s');
	outline: none;
	&:focus{
		border-color:$blue;
		@include box-shadow('0px 1px 1px rgba(0,0,0,.2) inset, 0 0 0.3em #{$blue}');
	}
	&:disabled{
		border:1px solid $lightgrey;
		@include box-shadow(none);
		background:#f0f0f0;
	}
}

input[type=text],input[type=email],input[type=password],input[type=number],input[type=url],input[type=date], textarea{
	@extend .std-input;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.styled-input{
	display:table;
	width:100%;
    margin-top: 1em;
	@include box-sizing(border-box);
	
	&>label{
		display:table-cell;
		cursor:pointer;
	}
	
	&>input, &>textarea{
		display:table-cell;
		@include box-sizing(border-box);
	}
	
	&.error>input, &.error>textarea{
		border-color:$orange;
	}
	
	&.req label:after{
		content:'*';
		vertical-align:super;
		font-size:0.8em;
		color:darkorange;
		margin-left:.1em;
	}

	&>label.attached{
		@extend .std-border;
		font-family:fontello;
		text-align:center;
		margin: 0.2em;
		vertical-align:middle;
		color:$blue;
		@include linear-gradient(#fafafa,#f3f3f3);
		text-shadow: 1px 1px 2px rgba(0,0,0,.2);
		
		&:first-child{
			border-right:0;
		}
		&:last-child{
			border-left:0;
		}
	}
	
	&:hover>label.attached{
		color:$blue;
	}
	
	.infobubble{
		display:table-cell;
	}

}