@import "../../lp3-common/scss/common-1.scss";
@import "../../lp3-common/scss/para-layout.scss";
@import "../../lp3-common/scss/_featuretable.scss";


#lp3-made-in-germany{ display:inline-block; width:191px; height:46px; background:url('../img/made-in-germany.png') no-repeat;}
#lp3-eyecatcher{ display:none; width:200px; height:200px; color:#4491c7; padding-left: 80px; text-align:center;}
@media(min-width:992px){ #lp3-eyecatcher{ display:inline-block; } }
#lp3-eyecatcher img{ width:100%; height:auto; display:block; }
/*#lp3-eyecatcher:before{ content:'\e838 + \e810 = \e803'; font-family: fontello; font-size:6rem; display:block; padding-left:3rem; padding-bottom:4rem; word-wrap: break-word;}*/


@media(max-width:767px){ #lp3-made-in-germany{ margin-top:-1.5rem; height:66px; width:201px;} }
@media(min-width:768px){ #lp3-made-in-germany{ margin-top:0; } }
@media(min-width:992px){ #lp3-made-in-germany{ margin-top:1rem; }}



/* editable areas */

#lp3-topics{margin-top:30px; padding:20px 0; text-align:center;}
#lp3-topics>div{display:inline-block; width:270px; vertical-align:top; margin:0;padding:0;}
#lp3-topics>div>i{color:#398C1C; border:2px solid #398C1C; border-radius:50%; display:block; width:1.6em; height:1.6em; }
.lp3-topic-middle{margin:auto 48px;}
#lp3-topics>div p{ color:#5682a4; text-align:left; font-weight:bold; line-height:18px; font-size:16px; margin-bottom:0;}

.prev-next{ text-align:left; padding: 30px 0; font-weight:bold; margin-top: 1em; }

.prev-next a{ margin:auto 30px; }

#right-nav-box{
	ul{
		padding: 0;
		list-style-type: none;
		li:first-child{
			margin-bottom: 0.5em;
			background-color: $blue;
			cursor: default;
			padding: 0.8rem 0;
			.right-nav-item{
				margin:0;
              	color: #fff;
				font-weight: normal;
				font-size: 1.1rem;
			}
		}
	}

	a{ line-height:2rem; font-size:1rem;}
	h4{text-align:center;}
	
}


#xmpl-survey{ width:60%; display:block; margin:auto; margin-top:20px; }
@media (min-width: 768px) { /* same as mobile */ }
@media (min-width: 992px) { #xmpl-survey{ position:absolute; right:-50px; margin-top:-30px;} }
@media (min-width: 1200px) { #xmpl-survey{ position:absolute; right:-10px; margin-top:-30px;} }


#musterlink{  }
@media (min-width: 600px) { #musterlink{ float:right; margin-top:100px;} }
@media (min-width: 768px) { #musterlink{ margin-top:100px; } }
@media (min-width: 992px) { #musterlink{ position:absolute; right:-50px; } }
@media (min-width: 1200px) { #musterlink{ position:absolute; right:-10px; margin-top:50px; } }

