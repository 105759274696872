input[type=radio]{
	@include box-shadow(none);
	border:0;
	vertical-align: middle;
	margin:0;
	margin-right:2px;
}

.gt-ie8 .styled-radiobutton{
	@extend .styled-checkbox;
	
	label{
		
		&:before{
			@include border-radius(50%);
		}
		&:after{
			content:'';
			width: #{($checksize)/3};
			height:#{($checksize)/3};
			top:#{($checksize)/3};
			left:#{($checksize)/3};
			@include border-radius(50%);
			background:white;
		}
	}
}