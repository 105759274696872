.plugin-emailform {
	.error,
	.info{
		border:1px solid #9B9974; background-color:#FFFDCB; display:none; padding:3px 6px; margin-bottom:6px;
	}
}
/* support-form */
#lp3-support{ 
	.support-tab{
		* { font-size:22px; }
		position:fixed; top:200px; right:-60px; width:160px; z-index:900; text-align: center;
		padding:4px 16px; border-bottom:0; cursor:pointer; margin-right:0; margin-left:auto;
		@include transform("rotate(-90deg)");
		@include opacity(75);
		&:hover{ @include opacity(100); }
		
		@media(max-width:767px){ width:65px; right:-33px; }
	}
	.support-body{
		padding:3em;
		padding-bottom: 1em;
		p{
			text-align: center;
			font-size: 0.9rem;
			max-width: 500px;
			margin: auto;
		}
	}
}


.plugin-emailform{
	max-width:500px;
	margin:auto;
    font-size: 0.9rem;
	
	label{
		//font-size:0.9em;
		color:$txt-grey;
		text-transform: uppercase;
		display:block;
		//margin-top:1em;
		padding-bottom: 0.1em;
	}
	input[type=email]{
		line-height: 1.6em;
	}

	input[type=text], textarea{
		display:block;
		width:100%;
	}
	
	textarea{
		height:150px;
	}
	.btn{
		//margin-top:1em;
		input{
			padding:0.7em 3em;
		}
		text-align:center;
	}

	.row{
		margin:0;
	}

	.emailform_submit{
		margin:1rem auto;
		display:block;
	}
}