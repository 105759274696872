@charset "UTF-8";
@import "_mixins";

#lamacms-body .para {
  .para-headline {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../lp3-common/img/para-bgs/background-1.jpg');


    /* default backgrounds */
    p.page-bg img {
      position: fixed;
      left: 0px;
      top: 0px;
      z-index: -1;
      height: 300px;
      width: 100%;
      min-width: 700px;
    }

    &.bg-Einsatzgebiete-fur-Umfragen, &.bg-Persoenlichkeitstest {
      background-image: url('../../lp3-common/img/para-bgs/umfrage-vorlage-events-1920a.jpg');
    }

    &[class*="Kontakt"], &[class*="Support"] {
      background-image: url('../../lp3-common/img/para-bgs/support-2000.jpg');
    }

    &[class*="Beispiele"], &[class*="Online-Fragebogen-erstellen"], &[class*="Fragebogen-gestalten"],
    &[class*="Fragebogen-Aufbau"], &[class*="Haufigsten-Fehler-Fragebogen-erstellen"],
    &[class*="Empfehlungen-fur-Ihren-Fragebogen"], &[class*="Haufige-Fehler-bei-Online-Umfragen"],
    &[class*="Fehlende-Antwortoptionen"], &[class*="Keine-webgerechte-Typografie"], &[class*="Langweiliger-Fragebogen"],
    &[class*="Matrixfragen-HF"], &[class*="Suggestive-Fragen"], &[class*="Ungenaue-Antwortoptionen"],
    &[class*="Ungenaue-Fragestellung"], &[class*="Unklare-Fragestellung"], &[class*="Zu-lange-Texte"],
    &[class*="Zu-lange-Umfragen"], &[class*="Zu-viele-Fragen"] {
      background-image: url('../../lp3-common/img/para-bgs/umfrage-beispiele-1920b.jpg');
    }

    &[class*="Umfrage-1"], &[class*="Online-Marktforschung"], &[class*="Markenbekanntheit-messen"],
    &[class*="Online-Befragung"], &[class*="Expertentipps-fuer-Online-Umfragen"], &[class*="Lieferantenselbstauskunft"],
    &[class*="Bedarfsanalyse"], &[class*="Wesentlichkeitsanalyse"], &[class*="attributes"] {
      background-image: url('../../lp3-common/img/para-bgs/online-marktforschung-2000.jpg');
    }

    &[class*="Mitarbeiterbefragung"],
    &[class*="Mitarbeiterbindung"],
    &[class*="Puls-Check"],
    &[class*="Home-Office-Befragungen"],
    &[class*="360-Grad-Feedback"],
    &[class*="Barrierefreie-Umfrage-erstellen"],
    &[class*="Beurteilungsbogen"],
    &[class*="Mitgliederbefragung"],
    &[class*="Mitgliederbefragung-Non-Profits-Vereine-Verbaende"] {
      background-image: url('../../lp3-common/img/para-bgs/mitarbeiterbefragung-2000.jpg');
    }

    &[class*="Mitarbeiterzufriedenheit"], &[class*="Zufriedenheit-Theorien-Modelle"], &[class*="Mitarbeiterquiz"],
    &[class*="Mitarbeiterengagement-messen-und-steigern"] {
      background-image: url('../../lp3-common/img/para-bgs/mitarbeiterzufriedenheit-2000.jpg');
    }

    &[class*="Kundenbefragung"], &[class*="Kundenzufriedenheit"], &[class*="net-promoter-score"],
    &[class*="Feedback-Tool"], &[class*="Online-Voting-erstellen"], &[class*="Buergerbefragung"],
    &[class*="Online-Formulare"], &[class*="LamaPoll-Erfahrungen-Bewertung"],
    &[class*="Einsatzmoeglichkeiten-Kundenumfrage"], &[class*="Mobile-Befragung"] {
      background-image: url('../../lp3-common/img/para-bgs/kundenzufriedenheit-2000.jpg');
    }

    &[class*="Umfrage-Tool"], &[class*="Umfrage-Software"], &[class*="Umfrage-Online"] {
      background-image: url('../../lp3-common/img/para-bgs/umfrage-tool-2000.jpg');
    }

    &[class*="Hilfe-Videos"], &[class*="FAQ"], &[class*="faq"] {
      background-image: url('../../lp3-common/img/para-bgs/hilfe-videos-2000.jpg');
    }

    &[class*="Datenschutz"], &[class*="datenschutz"], &[class*="Sicherheit"], &[class*="Impressum"], &[class*="AGB"],
    &[class*="Sichere-Alternative-Surveymonkey"] {
      background-image: url('../../lp3-common/img/para-bgs/datenschutz-sicherheit-2000.jpg');
    }

    &[class*="Online-Quiz-erstellen"], &[class*="Free"], &[class*="Testphase"] {
      background-image: url('../../lp3-common/img/para-bgs/online-quiz-erstellen-2000.jpg');
    }

    &[class*="Wissensquiz"] {
      background-image: url('../../lp3-common/img/para-bgs/wissenquiz-2000.jpg');
    }

    &[class*="fragebogen-vorlagen"] {
      background-image: url('../../lp3-common/img/para-bgs/umfrage-vorlage-1920b.jpg');
    }

    &[class*="Jobs"] {
      background-image: url('../../lp3-common/img/para-bgs/jobs-2000.jpg');
    }

    &[class*="Presse"] {
      background-image: url('../../lp3-common/img/para-bgs/presse-2000.jpg');
    }

    &[class*="Leistungen"], &[class*="Service"] {
      background-image: url('../../lp3-common/img/para-bgs/leistungen-2000.jpg');
    }

    &[class*="Umfrage-erstellen-in-6-Schritten"] {
      background-image: url('../../lp3-common/img/para-bgs/umfrage-6schritte-2000.jpg');
    }

    &[class*="gefaehrdungsbeurteilung-definition-tipps-regeln"] {
      background-image: url('../../lp3-common/img/para-bgs/gefaehrdungsbeurteilung-2000.jpeg');
    }

    &[class*="Betriebliche-Gesundheitsfoerderung"], &[class*="Betriebliches-Gesundheitsmanagement"],
    &[class*="Umweltrichtlinie"] {
      background-image: url('../../lp3-common/img/para-bgs/gesundheitsfoerderung-2000.jpeg');
    }

    &[class*="Details"] {
      background-image: url('../../lp3-common/img/para-bgs/features-and-details-2000.jpeg');
    }

    &[class*="vielendank"] {
      background-image: url('../../lp3-common/img/para-bgs/vielendank-2000.jpeg');
    }

    &[class*="Corona-Online-Erhebungen"] {
      background-image: url('../../lp3-common/img/para-bgs/corona-2000.jpg');
    }

    &[class*="Gastronomie-Kontaktdaten-erfassen"] {
      background-image: url('../../lp3-common/img/para-bgs/sunset-2000.jpg');
    }

    &[class*="Tool-Update"] {
      background-image: url('../../lp3-common/img/para-bgs/update-2000.jpg');
    }

    &[class*="Beispiel-Vorlage-Home-Office-Umfrage"], &[class*="Home-Office-Gefaehrdungsbeurteilung"],
    &[class*="Vorgehen-Home-Office-Umfrage"] {
      background-image: url('../../lp3-common/img/para-bgs/laptop-2000.jpg');
    }

    &[class*="Studenten-kostenlos"] {
      background-image: url('../../lp3-common/img/para-bgs/umfrage-vorlage-bildung-1920a.jpg');
    }

    &[class*="Umfragen-fur-das-Gesundheitswesen"] {
      background-image: url('../../lp3-common/img/para-bgs/doctor_1920.png');
    }

    @media(max-width: 767px) {
      &[class*="Kontakt"], &[class*="Support"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-support-500.png');
      }
      &[class*="Beispiele"], &[class*="Online-Fragebogen-erstellen"], &[class*="Fragebogen-gestalten"],
      &[class*="Fragebogen-Aufbau"], &[class*="Haufigsten-Fehler-Fragebogen-erstellen"],
      &[class*="Empfehlungen-fur-Ihren-Fragebogen"], &[class*="Haufige-Fehler-bei-Online-Umfragen"],
      &[class*="Fehlende-Antwortoptionen"], &[class*="Keine-webgerechte-Typografie"],
      &[class*="Langweiliger-Fragebogen"], &[class*="Matrixfragen-HF"], &[class*="Suggestive-Fragen"],
      &[class*="Ungenaue-Antwortoptionen"], &[class*="Ungenaue-Fragestellung"], &[class*="Unklare-Fragestellung"],
      &[class*="Zu-lange-Texte"], &[class*="Zu-lange-Umfragen"], &[class*="Zu-viele-Fragen"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-beispiele-500.png');
      }
      &[class*="Umfrage-1"], &[class*="Online-Marktforschung"], &[class*="Markenbekanntheit-messen"],
      &[class*="Online-Befragung"], &[class*="Expertentipps-fuer-Online-Umfragen"],
      &[class*="Lieferantenselbstauskunft"], &[class*="Bedarfsanalyse"], &[class*="Wesentlichkeitsanalyse"],
      &[class*="attributes"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-online-marktforschung-500.png');
      }
      &[class*="Mitarbeiterbefragung"], &[class*="Mitarbeiterbindung"], &[class*="Puls-Check"], &[class*="360-Grad-Feedback"],
      &[class*="Barrierefreie-Umfrage-erstellen"], &[class*="Beurteilungsbogen"], &[class*="Mitgliederbefragung"],
      &[class*="Mitgliederbefragung-Non-Profits-Vereine-Verbaende"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-mitarbeiterbefragung-500.png');
      }
      &[class*="Mitarbeiterzufriedenheit"], &[class*="Zufriedenheit-Theorien-Modelle"], &[class*="Mitarbeiterquiz"],
      &[class*="Mitarbeiterengagement-messen-und-steigern"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-mitarbeiterzufriedenheit-500.png');
      }
      &[class*="Kundenbefragung"], &[class*="Kundenzufriedenheit"], &[class*="net-promoter-score"],
      &[class*="Feedback-Tool"], &[class*="Online-Voting-erstellen"], &[class*="Buergerbefragung"],
      &[class*="Online-Formulare"], &[class*="LamaPoll-Erfahrungen-Bewertung"],
      &[class*="Einsatzmoeglichkeiten-Kundenumfrage"], &[class*="Mobile-Befragung"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-kundenzufriedenheit-500.png');
      }
      &[class*="Umfrage-Tool"], &[class*="Umfrage-Software"], &[class*="Umfrage-Online"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-umfrage-tool-500.png');
      }
      &[class*="Hilfe-Videos"], &[class*="FAQ"], &[class*="faq"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-hilfe-videos-500.png');
      }
      &[class*="Datenschutz"], &[class*="datenschutz"], &[class*="Sicherheit"], &[class*="Impressum"], &[class*="AGB"],
      &[class*="Sichere-Alternative-Surveymonkey"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-datenschutz-sicherheit-500.png');
      }
      &[class*="Online-Quiz-erstellen"], &[class*="Free"], &[class*="Testphase"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-online-quiz-erstellen-500.png');
      }
      &[class*="Wissensquiz"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-wissenquiz-500.png');
      }
      &[class*="fragebogen-vorlagen"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-fragebogen-vorlagen-500.png');
      }
      &[class*="Jobs"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-jobs-500.png');
      }
      &[class*="Presse"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-presse-500.png');
      }
      &[class*="Leistungen"], &[class*="Service"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-leistungen-500.png');
      }
      &[class*="Umfrage-erstellen-in-6-Schritten"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-umfrage-6schritte-500.png');
      }
      &[class*="gefaehrdungsbeurteilung-definition-tipps-regeln"] {
        background-image: url('../../lp3-common/img/para-bgs/responsive_gefaehrdungsbeurteilung-500.jpeg');
      }
      &[class*="Betriebliche-Gesundheitsfoerderung"], &[class*="Betriebliches-Gesundheitsmanagement"],
      &[class*="Umweltrichtlinie"] {
        background-image: url('../../lp3-common/img/para-bgs/responsive_gesundheitsfoerderung-500.jpeg');
      }
      &[class*="Details"] {
        background-image: url('../../lp3-common/img/para-bgs/features-and-details-500.jpeg');
      }
      &[class*="vielendank"] {
        background-image: url('../../lp3-common/img/para-bgs/mobile-vielendank-500.png');
      }
      &[class*="Corona-Online-Erhebungen"] {
        background-image: url('../../lp3-common/img/para-bgs/corona-500.jpg');
      }
      &[class*="Gastronomie-Kontaktdaten-erfassen"] {
        background-image: url('../../lp3-common/img/para-bgs/sunset-500.jpg');
      }
      &[class*="Tool-Update"] {
        background-image: url('../../lp3-common/img/para-bgs/update-500.jpg');
      }
      &[class*="Studenten-kostenlos"] {
        background-image: url('../../lp3-common/img/para-bgs/umfrage-vorlage-bildung-500.jpg');
      }
      &[class*="Beispiel-Vorlage-Home-Office-Umfrage"], &[class*="Home-Office-Gefaehrdungsbeurteilung"],
      &[class*="Vorgehen-Home-Office-Umfrage"] {
        background-image: url('../../lp3-common/img/para-bgs/laptop-500.jpg');
      }
      &[class*="Umfragen-fur-das-Gesundheitswesen"] {
        background-image: url('../../lp3-common/img/para-bgs/doctor_500.png');
      }
    }

    /* default backgrounds */

    &.bg-Studenten {
      background-image: url('../../lp3-common/img/para-bgs/umfrage-vorlage-bildung-1920a.jpg');
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 300px;
      content: " ";
      background: rgba(0, 0, 0, 0.5);
    }

    .content-headline {
      margin-top: 50px;
    }

    &, h1, p {
      color: white;
      text-shadow: 3px 3px 5px #000000;
    }

    p {
      font-size: 1.3em;
    }

    @media(max-width: 767px) {
      p {
        display: none;
      }
      p.page-bg {
        display: block;
      }
    }
  }

  .para-body {
    position: relative;
    margin-top: 300px;
    background: #f9f9f9;
  }

  @media(min-width: 1366px) {
    .para-headline {
      height: 500px;

      p.page-bg img {
        height: 500px;
      }

      &:before {
        height: 500px;
      }

      h1 {
        font-size: 3.5rem;
        line-height: 5rem;
      }
    }
    .para-body {
      margin-top: 500px;
    }
  }
}

.backend #lamacms-body .para {
  .para-headline {
    position: static;

    p.page-bg {
      position: absolute;
      bottom: -180px;

      img {
        position: relative;
        min-width: 50px;
        width: 100px;
        height: 50px;
        z-index: 0;
      }
    }
  }

  .para-body {
    margin: 0;
  }
}
