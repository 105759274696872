@import "lp3-cd.scss";

.head-bar {
  top: 0;
  left: 0;
  right: 0;
  height: 50px;

  background-color: $blue;
  z-index: 999;
  @include box-shadow(0 1px 3px rgba(0, 0, 0, .3));

  .head-content {
    height:50px;
    max-width:1600px;
    margin:auto;
  }

  li.nav-item-container {
    border-right: 1px dotted #fff;
    vertical-align: top;
  }
  li.first {
    border-left: 1px dotted #fff;
  }

  a.nav-item {
    display: inline-block;
    border-top: 3px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    padding: 12px 3px;
    font-size: 16px;
    text-decoration: none;
    @media (min-width: 340px) {
      padding: 12px 7px;
    }
    @media (min-width: 768px) {
      padding: 12px 7px;
    }

    @media (min-width: 1200px) {
      padding: 12px 20px;
    }
    @include transition('all .25s');
    &:hover:not(.header-reg-button) {
      text-decoration: none;
      border-top-color: rgba(255, 255, 255, 0.8);
    }
    &.active:not(.header-reg-button) {
      border-top-color: white;
      color: #ffffff;
    }

    &.header-reg-button {
      @extend .lp3-btn-green;
      padding: 6px 10px !important;
      font-weight: normal;
    }

  }

  .submenu-marker {
    margin-left: 5px;
    display: inline-block;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: #ffffff;
  }

  .collapsed-nav ul {
    background-color: white;
    padding: 0;
    margin: 0;
    border: 1px solid $lightgrey;
    border-top: 0;
    box-shadow: -3px 3px 3px -2px rgba(0, 0, 0, 0.25);
    opacity:1;

    li{
      min-width: 100px;
      border-bottom: 1px solid $lightgrey;
      &:last-child{
        border-bottom:0;
      }
    }
  }

  .collapsed-item {
    display: block;
    color:$txt-grey;
    padding: 1em 2em;
    text-decoration: none;
  }

}

.logo-link {
  display: inline-block;
  vertical-align:top;
  height: 100%;
  img {
    height: 100%;
    max-height:100%;
    width: auto;
	padding:0.5rem 1rem;
  }
}

.nav-hori {
  display: inline-block;
  vertical-align:top;
  height: 50px;
  width:100%;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    display: inline-block;
  }
  li {
    display: inline-block;
  }
}

#head-contacts {
  float: none;
  display: none;
  @media (min-width: 992px) {
    display: inline-block;
  }
  padding-left: 3rem;

  a {
    white-space: nowrap;
  }
}

#head-reg-btn {
  float:right;
  li {
    display: inline-block;
    a {
      font-size: 1em;
    }
  }
}

/* collapsable nav */
.collapsed-nav {
  display: none;
  position: absolute;
  right: 0;
  li {
    display: block;
  }
  &.last {
    border-bottom: 0;
  }
}

.mobile-nav a.nav-item {
  border: 0;
  font-size: 2.2em;
  padding: 5px 0;
  margin: 0;
  margin-bottom: -1px;
}

/* footer */
.foot-bar {
  color: white;
  line-height: 2em;
  background: #333333; /* Old browsers */
}

.flag {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

#flag-black {
  background: #000000;
  margin-right: 3px;
}

#flag-red {
  background: #ff0000;
  margin-right: 3px;
}

#flag-gold {
  background: gold;
}