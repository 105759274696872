@import "_colors.scss";

table.lp-table{
	margin: 1em 0;
	border: 1px solid rgba(34,36,38,.15);
	vertical-align: middle;
	border-collapse: separate;
	border-spacing: 0;
	text-align: left;
	> thead{
		&> tr {
			&>th{
				&:first-child{
					border-left: none;
				}
				color: darken($txt-grey, 20%);
				padding: 0.7em;
				cursor: auto;
				background: #f3f3f3;
				vertical-align: inherit;
				font-weight: 700;
				border-left: 1px solid $lightgrey;
				border-bottom: 1px solid $lightgrey;
			}
		}
	}
	> tbody{
		&> tr {
			&:first-child{
				&>td{
					border-top: none;
				}
			}
			&> td{
				&:first-child{
					border-left: none;
				}
				color: darken($txt-grey, 20%);
				padding: 0.5em 0.7em;
				cursor: auto;
				vertical-align: inherit;
				border-left: 1px solid rgba(34,36,38,.1);
				border-top: 1px solid rgba(34,36,38,.1);
			}
		}
	}
	&.responsive-table {

		@media screen and (max-width: 767px){
			border: none;
			thead {
				display: none;
			}
			tbody {
				display: block;
				>tr:first-child>td {
					&:not(:first-child) {
						border-top: 1px solid rgba(34,36,38,0.1);
					}
				}
			}
			tr {
				display: block;
				border: 1px solid rgba(34,36,38,0.1);
				margin-bottom: 1.5em;
			}
			td {
				display: flex;
				align-items: flex-start;
				border-left: none;

				&:first-child {
					border-top: none;
					* {
						display: contents;
						min-width: auto;
					}
				}
				&:before {
					content: attr(data-name);
					font-weight: bold;
					width: 9em;
					min-width: 9em;
				}
			}
		}
	}
}

table{
	tr{
		&.positive  {
			background-color: rgba(97,189,96,.8);
		}
		&.negative  {
			background-color: rgba(216,84,80,0.8);
		}
		&.neutral  {
			background-color: rgba(240,203,83,.8);
		}
	}
}

