.page-overlay{
	visibility:hidden; &.visible{ visibility:visible; }
	
	z-index:9999;
	.shadow{
		position:fixed;
		top:0; left:0;right:0;bottom:0;
		background-color:rgba(0,0,0,0.3);
		z-index:9999;
		visibility:hidden; opacity:0;
		@include transition('opacity 0.1s linear, visibility 0s linear 0.25s');
	}
	
	&.visible .shadow{
		opacity:1; visibility: visible; @include transition('opacity 0.1s linear');}
	
	.overlay-content{
		width:300px;
		@media( min-width:768px ){ width:600px; }
		@media( min-width:992px ){ width:700px; }
		
		position:fixed;
		top:0%;  bottom:20%; margin:0 auto;left:0; right:0;
		background: white;
		border:1px solid $blue;
		z-index:9999;
		visibility:hidden; opacity:0;
		font-size:0.9em;
		@include transition('width 0.1s linear, box-shadow 0.1s linear, opacity 0.1s linear, top 0.1s linear, left 0.1s linear, right 0.1s linear, bottom 0.1s linear, visibility 0s linear 0.35s');
		@include box-shadow('0 0 10px rgba(0,0,0,0.3)');
		
		h2{
			margin-bottom:0.25em!important;
		}
		
		h4{
			margin-top:0!important;
			margin-bottom:1.25em!important;
		}
	}
	
	&.visible .overlay-content{
		opacity:1; 
		top:10%; bottom:10%; margin:0 auto;left:0; right:0;
		width:300px;
		@media( min-width:768px ){ width:600px; }
		@media( min-width:992px ){ width:700px; }
		
		overflow:auto;
		
		visibility: visible;
		@include transition('width 0.1s linear 0.15s, box-shadow 0.1s linear 0.15s, opacity 0.1s linear 0.15s, top 0.1s linear 0.15s, left 0.1s linear 0.15s, right 0.1s linear 0.15s, bottom 0.1s linear 0.15s');
		.lp-pi{ padding:2em;
			.more-info{
				text-align: center;
				.box1 {h2,h3 {color:$green!important;}}
				.box2 {h2,h3 {color:$orange!important;}}
				.box3 {h2,h3 {color:$txt-grey!important;}}
				.box4 {h2,h3 {color:$blue!important;}}
			}
		}
	}
	
	/* Ausnahmen für Support-form */
	&.support-form .overlay-content{
		top:10%;  bottom:10%; margin:0 auto;left:10%; right:0;
		max-height: 35em; //alt 430px
	}
	
	&.visible.support-form .overlay-content{
		top:10%; bottom:10%; margin:0 auto;left:0; right:0;
	}
	
	.close-btn{
		position:absolute;
		right:0; top:0;
		width:40px; height:40px;
		display:block;
		text-align: center;
		text-decoration: none;
		
		&:before{
			font-family: fontello;
			content: '\e851';
			color:$blue;
			font-size: 18px;
			line-height: 40px;
		}
	}
}


#lamacms-innercenter .page-overlay .shadow, 
#lamacms-innercenter .page-overlay .overlay-content
{position:absolute;}