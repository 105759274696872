@mixin rotate($degree){
	-moz-transform: rotate(#{$degree});
	-ms-transform: rotate(#{$degree});
	-o-transform: rotate(#{$degree});
	-webkit-transform: rotate(#{$degree});

	transform: rotate(#{$degree});
}

@mixin flipH(){
	@include rotateY( 180deg );
	filter: FlipH;
	-ms-filter: "FlipH";
}

@mixin rotateY($degree){
	-moz-transform: rotateY(#{$degree});
	-ms-transform: rotateY(#{$degree});
	-o-transform: rotateY(#{$degree});
	-webkit-transform: rotateY(#{$degree});

	transform: rotateY(#{$degree});
}

@mixin perspective($perspective){
	-webkit-perspective: #{$perspective};
	-moz-perspective: #{$perspective};
	-ms-perspective: #{$perspective};
	perspective: #{$perspective};
}

@mixin box-shadow($shadow){
	box-shadow: #{$shadow};
	-moz-box-shadow: #{$shadow};
	-webkit-box-shadow: #{$shadow};
}

@mixin box-sizing($sizing){
	box-sizing: #{$sizing};
	-moz-box-sizing: #{$sizing};
	-webkit-box-sizing: #{$sizing};
}

@mixin transition($transitions){
	transition: #{$transitions};
	-moz-transition: #{$transitions};
	-webkit-transition: #{$transitions};
}

@mixin transition-duration($transitiondur){
	transition-duration: #{$transitiondur};
	-moz-transition-duration: #{$transitiondur};
	-webkit-transition-duration: #{$transitiondur};
}

@mixin linear-gradient($fromColor, $toColor) {
	background-color: $toColor; /* Fallback Color */
	background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
	background-image: -webkit-linear-gradient(top, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
	background-image:    -moz-linear-gradient(top, $fromColor, $toColor); /* FF3.6 */
	background-image:     -ms-linear-gradient(top, $fromColor, $toColor); /* IE10 */
	background-image:      -o-linear-gradient(top, $fromColor, $toColor); /* Opera 11.10+ */
	background-image:         linear-gradient(top, $fromColor, $toColor);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}

@mixin set-border($topLeftColor, $bottomRightColor){
	border:1px solid $topLeftColor;
	border-right-color:$bottomRightColor;
	border-bottom-color:$bottomRightColor;

}

@mixin border-radius($radius){
	-webkit-border-radius: #{$radius};
	-moz-border-radius: #{$radius};
	border-radius: #{$radius};
}

@mixin border-radius-top($radius){
	border-top-left-radius: #{$radius};
	border-top-right-radius: #{$radius};
	-moz-border-radius-topleft: #{$radius};
	-moz-border-radius-topright: #{$radius};
	-webkit-border-top-left-radius: #{$radius};
	-webkit-border-top-right-radius: #{$radius};
}

@mixin border-radius-right($radius){
	border-top-right-radius: #{$radius};
	border-bottom-right-radius: #{$radius};
	-moz-border-radius-topright: #{$radius};
	-moz-border-radius-bottomright: #{$radius};
	-webkit-border-top-right-radius: #{$radius};
	-webkit-border-bottom-right-radius: #{$radius};
}
@mixin border-radius-top-right($radius){
	border-top-right-radius: #{$radius};
	-moz-border-radius-topright: #{$radius};
	-webkit-border-top-right-radius: #{$radius};
}
@mixin border-radius-bottom-right($radius){
	border-bottom-right-radius: #{$radius};
	-moz-border-radius-bottomright: #{$radius};
	-webkit-border-bottom-right-radius: #{$radius};
}

@mixin border-radius-left($radius){
	border-top-left-radius: #{$radius};
	border-bottom-left-radius: #{$radius};
	-moz-border-radius-topleft: #{$radius};
	-moz-border-radius-bottomleft: #{$radius};
	-webkit-border-top-left-radius: #{$radius};
	-webkit-border-bottom-left-radius: #{$radius};
}

@mixin border-radius-top-left($radius){
	border-top-left-radius: #{$radius};
	-moz-border-radius-topleft: #{$radius};
	-webkit-border-top-left-radius: #{$radius};
}
@mixin border-radius-bottom-left($radius){
	border-bottom-rigleftht-radius: #{$radius};
	-moz-border-radius-bottomleft: #{$radius};
	-webkit-border-bottom-left-radius: #{$radius};
}

@mixin border-radius-bottom($radius){
	border-bottom-left-radius: #{$radius};
	border-bottom-right-radius: #{$radius};
	-moz-border-radius-bottomleft: #{$radius};
	-moz-border-radius-bottomright: #{$radius};
	-webkit-border-bottom-left-radius: #{$radius};
	-webkit-border-bottom-right-radius: #{$radius};
}


@mixin opacity($opacity){
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity})";
	filter: alpha(opacity=#{$opacity});
	opacity: #{$opacity / 100};
}

@mixin transform($transform){
	-webkit-transform: #{$transform};
	-moz-transform: #{$transform};
	-o-transform: #{$transform};
	-ms-transform: #{$transform};
	transform: #{$transform};
}

@mixin transform-style($transformstyle){
	-webkit-transform-style: #{$transformstyle};
	-moz-transform-style: #{$transformstyle};
	-ms-transform-style: #{$transformstyle};
	transform-style: #{$transformstyle};
}

@mixin outset-border($width, $style, $color){
	border: $width $style $color;
	border-bottom-color: darken($color, 20%);
	border-right-color: darken($color, 20%);
}

@mixin backface-visibility($value){
	backface-visibility:#{$value};
	-webkit-backface-visibility:#{$value};
	-moz-backface-visibility:#{$value};
	-ms-backface-visibility:#{$value};
}

@mixin hyphens($value){
	-moz-hypens: #{$value};
	-ms-hypens: #{$value};
	-webkit-hypens: #{$value};
	hyphens: #{$value};
}