select{
	@extend .std-input;
	width:auto;
}

.styled-select{
	@extend .styled-input;
	&>select{
		display:table-cell;
	}
	
	&.error>select{
		border-color:orange;
	}
}

.lp-menubar-styled-select{
	display:inline-block;
	vertical-align:top;
	select{ font-size:0.9em; }
}

/* war eigentlich weg. is durch test-merge hier. muss ich gucken, ob ich das brauche

.lp-menubar-styled-select{background:url('/lp/img/bg.selectbox.png') no-repeat right #fff;overflow:hidden;width:60px; height:25px;display:inline-block;vertical-align:top;}
.lp-menubar-styled-select:hover{background:url('/lp/img/bg.selectbox.hover.png') no-repeat right #f5f9fc;}
.lp-menubar-select{@include set-border(lighten($blue, 20), darken($blue,10)); padding:0; margin:0!important;cursor:pointer;width:85px; height:25px;background:transparent;display:inline-block; -webkit-appearance:none; vertical-align:top;}
.lp-menubar-select:hover{}
.lp-menubar-select option{padding:4px;}

*/