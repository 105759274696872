@import "/fonts/fontello-9401d276/css/fontello.css";
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/open-sans/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('/fonts/open-sans/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/open-sans/open-sans-v15-latin-regular.woff2') format('woff2'), /*  Super Modern Browsers */
  url('/fonts/open-sans/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/open-sans/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/open-sans/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/open-sans/open-sans-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('/fonts/open-sans/open-sans-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/open-sans/open-sans-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/open-sans/open-sans-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/open-sans/open-sans-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/open-sans/open-sans-v15-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/open-sans/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('/fonts/open-sans/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/open-sans/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/open-sans/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/fonts/open-sans/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/open-sans/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/open-sans/open-sans-v15-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('/fonts/open-sans/open-sans-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/open-sans/open-sans-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/open-sans/open-sans-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/open-sans/open-sans-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/open-sans/open-sans-v15-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}


/* ubuntu-regular - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Italic'), local('Ubuntu-Italic'),
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'),
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/ubuntu-v12-latin/ubuntu-v12-latin-700italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}








/*
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Open-Sans/OpenSans-Bold.ttf');
}
@font-face {
  font-family: 'Droid Sans';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Droid-Sans-regular/Droid-Sans-regular.eot');
  src: url('/fonts/Droid-Sans-regular/Droid-Sans-regular.eot?#iefix') format('embedded-opentype'),
       local('Droid Sans'),
       local('Droid-Sans-regular'),
       url('/fonts/Droid-Sans-regular/Droid-Sans-regular.woff2') format('woff2'),
       url('/fonts/Droid-Sans-regular/Droid-Sans-regular.woff') format('woff'),
       url('/fonts/Droid-Sans-regular/Droid-Sans-regular.ttf') format('truetype'),
       url('/fonts/Droid-Sans-regular/Droid-Sans-regular.svg#DroidSans') format('svg');
}

@font-face {
  font-family: 'Droid Sans';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Droid-Sans-700/Droid-Sans-700.eot');
  src: url('/fonts/Droid-Sans-700/Droid-Sans-700.eot?#iefix') format('embedded-opentype'),
       local('Droid Sans Bold'),
       local('Droid-Sans-700'),
       url('/fonts/Droid-Sans-700/Droid-Sans-700.woff2') format('woff2'),
       url('/fonts/Droid-Sans-700/Droid-Sans-700.woff') format('woff'),
       url('/fonts/Droid-Sans-700/Droid-Sans-700.ttf') format('truetype'),
       url('/fonts/Droid-Sans-700/Droid-Sans-700.svg#DroidSans') format('svg');
}
*/
